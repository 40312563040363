pre {
    padding: 5px;
    margin: 15px;
}
.string {
color: lightgreen;
}
.number {
color: lightsalmon;
}
.boolean {
color: lightblue;
}
.null {
color: magenta;
}
.key {
color: white;
}