.loading {
  margin-top: 16px;
  //font-family: "Poppins";
  font-size: 16px;
  color: #175c83;
  //background: "linear-gradient(144deg, rgba(11,46,65,1) 0%, rgba(23,92,131,1) 86%)";
}

.loading:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

// @keyframes dots {
//   0%, 20% {
//     color: rgba(0,0,0,0);
//     text-shadow:
//       .25em 0 0 rgba(0,0,0,0),
//       .5em 0 0 rgba(0,0,0,0);}
//   40% {
//     color: #175c83;
//     text-shadow:
//       .25em 0 0 rgba(0,0,0,0),
//       .5em 0 0 rgba(0,0,0,0);}
//   60% {
//     text-shadow:
//       .25em 0 0 #175c83,
//       .5em 0 0 rgba(0,0,0,0);}
//   80%, 100% {
//     text-shadow:
//       .25em 0 0 #175c83,
//       .5em 0 0 #175c83;}
// }

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #175c83;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #175c83,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #175c83,
      .5em 0 0 #175c83;}
}
