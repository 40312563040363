/* .product-listing-page
  .filter-wrapper
  .category-search
  .ais-Panel
  .ais-HierarchicalMenu
  .ais-HierarchicalMenu-list
  .ais-HierarchicalMenu-link::before {
  content: url("../../assets/images/other/arrow-small.png") !important;
  width: 10px !important;
  position: relative !important;
  top: 2px !important;
} */

/* .ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before {
  transform: unset !important;
} */

.variant {
  width: 100%;
  padding: 10px;
  border: 1px solid #175c83a1;
  border-radius: 10px;
  margin-bottom: 10px;

  .variant-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .variant-name {
      width: 50%;
    }

    .variant-buttons {
      display: flex;
      gap: 20px;

      .variant-icon {
        font-size: 20px;
        cursor: pointer;
      }   
    }
  }

  .variant-expanded-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #d9d9d9; */
    padding-bottom: 10px;
  }

  .collapsible {
    width: 100%;

    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 0;
      /* border-bottom: 1px solid #d9d9d9; */
      border-top: 1px solid #d9d9d9;      

      .section-title {
        /* font-family: "Poppins"; */
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #313133;  
      }

      .section-buttons {
        display: flex;
        gap: 20px;

        .section-icon {
          font-size: 15px;

          cursor: pointer;
        }
      }

    }

    .content {
      padding-top: 10px;
      padding-bottom: 10px;
      /* border-bottom: 1px solid #d9d9d9;       */
    }
  }

  .collapsible-active {
    .section-header {
      border-bottom: none;
    }
  }
}